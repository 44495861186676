
import { readCartSidebarShow } from '@/store/main/getters';
import { commitSetCartSidebarShow } from '@/store/main/mutations';
import { dispatchGetInvoiceDetails, dispatchGetRecentInvoiceDetails, dispatchRemoveFromCart, dispatchToggleProductInCart } from '@/store/products/actions';
import { readInvoiceDetail, readProductsInvoice } from '@/store/products/getters';
import { commitSetProductsInvoice } from '@/store/products/mutations';
import { getLocalSessionId } from '@/utils';
import { Component, Vue } from 'vue-property-decorator';
import BuyOneClick from '@/components/modals/BuyOneClick.vue';
// import ReviewUnknown from '@/components/modals/ReviewUnknown.vue';
// import ReviewUser from '@/components/modals/ReviewUser.vue';
// import ReviewShare from '@/components/modals/ReviewShare.vue';
// import ChangePassword from '@/components/modals/ChangePassword.vue';

@Component({
    components: {
      BuyOneClick
        // ReviewUnknown,
        // ReviewUser,
        // ReviewShare,
        // ChangePassword
    },
})

export default class CartSidebar extends Vue {
  public showBuyOneClickSidebar: boolean = false;

  public async mounted() {
    const response = await dispatchGetRecentInvoiceDetails(this.$store, {anon_session_id: getLocalSessionId(),});
    if (response) {
      commitSetProductsInvoice(this.$store, response);
    }
  }

  public async handleInputCountNumber(id) {
    let inCart: any = null;
    if (this.invoiceDetails) {
      for (let i in this.invoiceDetails.items) {
        // @ts-ignore
        if (this.invoiceDetails.items[i].object_id === id) {
          if (this.invoiceDetails.items[i].quantity && isNaN(this.invoiceDetails.items[i].quantity)) {
            this.invoiceDetails.items[i].quantity = 1
          } else if (this.invoiceDetails.items[i].quantity && Number(this.invoiceDetails.items[i].quantity) <= 0) {
            this.invoiceDetails.items[i].quantity = 1
          }
          inCart = {
            id: this.invoiceDetails.items[i].id,
            quantity: this.invoiceDetails.items[i].quantity,
          };
        }
      }
    }
    if (inCart) {
      if (inCart.quantity && Number(inCart.quantity) && Number(inCart.quantity) > 0) {
        await dispatchToggleProductInCart(this.$store, {invoice_item: inCart.id, action: 'count', quantity: Number(inCart.quantity)});
      }
    }
  }

  public async handleAddToCart(id) {
    let inCart: any = null;
    if (this.invoiceDetails) {
      for (let i in this.invoiceDetails.items) {
        if (this.invoiceDetails.items[i].object_id === id) {
          inCart = {
            id: this.invoiceDetails.items[i].id,
            quantity: this.invoiceDetails.items[i].quantity,
          };
        }
      }
    }
    if (inCart) {
      await dispatchToggleProductInCart(this.$store, {invoice_item: inCart.id, action: 'add'});
    }
  }

  public async handleRemoveFromCart(id) {
    if (this.invoiceDetails) {
      for (let i in this.invoiceDetails.items) {
        if (this.invoiceDetails.items[i]) {
          if (this.invoiceDetails.items[i].object_id === id && this.invoiceDetails.items[i].quantity > 1) {
            await dispatchToggleProductInCart(
              this.$store, {invoice_item: this.invoiceDetails.items[i].id, action: 'remove'},
            );
          }
        }
      }
    }
  }

  public async handleDeleteFromCart(id) {
    setTimeout(async () => {
      await dispatchRemoveFromCart(this.$store, {id, anon_session_id: getLocalSessionId()});
      if (this.productsInvoice) {
        dispatchGetInvoiceDetails(this.$store, {invoice_id: this.productsInvoice, anon_session_id: getLocalSessionId()});
      }
    }, 500);
  }

  public toggleCartSidebar() {
    if (!this.sidebarShow ) {
      this.$emit('show')
    }
    commitSetCartSidebarShow(this.$store, !this.sidebarShow);
  }

  get invoiceDetails() {
    return readInvoiceDetail(this.$store);
  }
  get productsInvoice() {
    return readProductsInvoice(this.$store);
  }
  get sidebarShow() {
    return readCartSidebarShow(this.$store);
  }
}
