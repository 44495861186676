
import { Component, Vue, Prop } from 'vue-property-decorator';
import { readProductsCategoriesList } from '@/store/products/getters';
import $ from 'jquery';

@Component({
    components: {
    },
})

export default class SidebarDesc extends Vue {
  @Prop({required: false, default: []}) public popularKindsProductsList!: any[]

  public handleChangeCatalog(category) {
    this.$router.push(`/catalog/${category.codename}`);
  }

  public handleChangePopularKind(id) {
    this.$router.push(`/products/kinds/list/${id}`);
  }

  get categoriesResponse() {
      return readProductsCategoriesList(this.$store);
  }
}
