
import { IFeedbackForm } from '@/interfaces';
import { dispatchSubmitSendFeedback } from '@/store/special_offers/actions';
import { Component, Vue } from 'vue-property-decorator';
import { mask } from 'vue-the-mask';

@Component({
    directives: {mask},
    components: {
    },
})

export default class FeedbackForm extends Vue {
  public feedback_form: IFeedbackForm = {
    name: '',
    email: '',
    phone: '',
    message: '',
  };

  public async handleSubmit(hide) {
    if (!this.formValid) {
        this.$toast.error(this.$t('Заполните анкету').toString());
        return;
    }
    const result = await dispatchSubmitSendFeedback(this.$store, this.feedback_form);
    if (result) {
        this.$toast.success(this.$t('Заявка отправлена').toString());
        hide();
        this.resetForm();
    } else {
        this.$toast.error(this.$t('Ошибка').toString());
    }
  }

  public resetForm() {
    this.feedback_form = {
        name: '',
        email: '',
        phone: '',
        message: '',
    };
  }

  get formValid() {
    return (this.feedback_form.name && (this.feedback_form.email || this.feedback_form.phone) && this.feedback_form.message)
  }

}
